.f-login-container {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.f-login {
		min-width: 350px;
		padding: 30px 50px;
		border: 1px solid #f1f1f1;
		border-radius: 3px;

		.logo {
			margin-bottom: 15px;
			text-align: center;
			img {
				height: 35px;
				width: auto;
			}
		}
	}
}

.login-form-button {
	width: 100%;
}
