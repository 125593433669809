@import "~antd/dist/antd.css";
@import "./variable.scss";

@font-face {
    font-family: "HiraKakuProW3";
    src: url("../fonts/hirakakupro-w3-opentype.otf") format("opentype");
}

@font-face {
    font-family: "HiraKakuProW6";
    src: url("../fonts/hirakakupro-w6.otf") format("opentype");
}

.container {
    padding: 15px;
    @media (min-width: 576px) {
        padding: 12px;
    }
    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
        // max-width: 760px;
    }
    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
        // max-width: 980px;
    }
    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
        // max-width: 1000px;
    }
    @media (min-width: 1280px) {
        padding: 15px 160px;
    }
}

.admin-container,
.header-container {
    padding: 15px;
    @media (min-width: 576px) {
        padding: 12px;
    }
    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
        // max-width: 760px;
    }
    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
        // max-width: 980px;
    }
    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
        // max-width: 1000px;
    }
    @media (min-width: 1280px) {
        padding: 15px 50px;
    }
}

*,
body,
html {
    font-family: "HiraKakuProW3";
}

.table-sticky-header {
    th {
        background: #d8aa73 !important;
        z-index: 999;
        position: sticky !important;
        top: 63px;
        border-bottom: none;
        border-radius: 0 !important;
    }

    .ant-spin-blur {
        overflow: unset !important;
    }
}

.ant-input-number {
    width: 100% !important;
}

.search-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ant-pagination-item {
    display: none !important;
}

.cursor-pointer {
    cursor: pointer;
}
