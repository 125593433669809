@import "../../../assets/scss/variable.scss";

.default-header {
	background: #fff !important;
	position: fixed;
	z-index: 1;
	width: 100%;
	box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
	padding-top: 0 !important;
    padding-bottom: 0 !important;

	.r-menu {
		float: right;
		height: 100%;
		display: flex;

		.menu-item {
			line-height: 1;
			height: 64px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 0 0 0 25px;
			cursor: pointer;

			&:hover .title {
				color: $primary;
				-webkit-transition: all 0.3s ease;
				transition: all 0.3s ease;
			}

			.title {
				font-size: 12px;
				margin-top: 5px;
			}
		}
	}

	.logo {
		width: 120px;
		height: 35px;
		margin: 12px 24px 12px 0;
		float: left;
		justify-content: center;
		align-items: center;
		display: flex;
		cursor: pointer;
		img {
			width: 100%;
			object-fit: cover;
		}
	}
}
